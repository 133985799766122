







































































































































import { Prop, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { ISideBar, IUser } from '@/types/types';
import { Getter } from 'vuex-class';
@Component({
  name: 'SideBarDrawer',
})
export default class SideBarDrawer extends Vue {
  @Prop({ required: false, default: false }) drawer!: boolean;
  @Getter('getNavigationDrawerState') drawerState!: boolean;

  @Prop({ required: true, default: () => [] }) links!: ISideBar[];
  @Prop({ required: true, default: null }) user!: IUser;

  // @Emit("closeOnOutsideClick")
  closeOnOutsideClick(payload: boolean): void {
    this.$store.dispatch('navigationDrawer', payload, { root: true });
  }

  @Watch('drawerState')
  onDrawerChange(value: boolean): boolean {
    return value;
  }
}
